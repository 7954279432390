import {Container, Segment, Header} from "semantic-ui-react";
import React from 'react';
import NavBar from './Components/NavBar'


function About() {
    return(
        <div>
            <Container
            textAlign='center'
            fluid 
            style={{
                background: '#070707',
                color: '#070707',
                height: '100vh'
                }}>
                <NavBar active="About"/>
                <Container fluid textAlign='center' style={{height:"100%"}}>
                    <Segment inverted size='massive' style=
                    {{lineHeight: "1.6", 
                    height: "50%", 
                    verticalAlign: "middle",
                    position: "absolute",
                    width: "70%",
                    left: "15%",
                    top: "25%",
                    borderRadius: "50px",
                    backgroundColor: "C4C4C4"
                    }}>
                        <Header as='h1' content="Our Vision">
                        </Header>
                        Poetic is a simplified word-processor tailored towards creative writing. That includes poetry, rap, songwriting and anything else that attempts to leverage the power of words. Coming features include rhyme-scheme recognition and a more refined word processor. Poetic is still in its beta version.     
                    </Segment>
                </Container>
            </Container>
            
        </div>
    )
}
export default About;