import React, { useState } from 'react'
import 'fomantic-ui-css/semantic.css';
import { Menu, Segment} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

function NavBar(props) {

    const NavBarStyling = {
        //color: '#F6C630' !important,
    }
    const [activeItem, setActiveItem] = useState(props.active);
    const handleItemClick = (e, { name }) => setActiveItem(name)

    
    return(
        // <Segment color='yellow' inverted style={{color: '#F6C630 !important'}}>
            <Menu 
            position='right'
            borderless={true} 
            size='massive'
            color='black'
            pointing
            secondary   
            inverted
            style={{
                background: '#070707',
                color: '#070707'
                }}>
                {/* <Menu.Item
                className='poetic'
                size='large'>
                    <b>Poetic</b>
                </Menu.Item> */}
                <Link to='/'>
                    <Menu.Item 
                    className='home'
                    name='Home'
                    active={activeItem === "Home"}
                    link
                    onClick={handleItemClick}/>
                </Link>
                <Link to='/about'>
                    <Menu.Item 
                    className='about'
                    name='About'
                    active={activeItem === "About"}
                    link
                    onClick={handleItemClick}/>
                </Link>
                {/* <Link to='/editor'>
                    <Menu.Item 
                    className='premium'
                    link>
                    Premium
                    </Menu.Item>
                </Link> */}
                {/* <Link to='/editor'>
                    <Menu.Item 
                    className='contact'
                    link>
                    Contact
                    </Menu.Item>
                </Link> */}
            </Menu>

    );
}
export default NavBar;