
import React from 'react';
import './App.css';
import 'fomantic-ui-css/semantic.css';
import { Container, Button, Icon, Segment, Card, Grid, Header} from 'semantic-ui-react';
import NavBar from './Components/NavBar'
import {Link} from 'react-router-dom';

function Home() {
    return(
        <div>
            <Container 
            textAlign='center'
            fluid 
            style={{
                background: '#070707',
                color: '#070707',
                height: '100vh',
                verticalAlign: 'middle'
                }}>
                <NavBar active="Home"/>
                <Segment
                className='CardPortal'
                size='massive'
                inverted
                fluid
                padded='very'
                textAlign='center'
                style={{
                    background: '#070707',
                    color: '#070707'
                    }}>
                        <Header 
                            as="h1" 
                             
                            size='huge'
                            color='yellow'>
                                <i>POETIC</i>
                        </Header>
                        <Header
                            as="h2"
                            content="Enjoy integrated rhyme suggestion."
                            size='huge'/>
                        <Link to="/editor">
                                <Button
                                icon
                                color='yellow'
                                size='big'
                                inverted
                                content="Start Writing">
                                    Start Writing &ensp;
                                    <Icon  name='right arrow'/>
                                </Button>
                                
                        </Link>
                    {/* <Card position='right' inverted>
                        <Card.Content position='right'>
                            <Card.Description>
                            Enjoy integrated rhyme suggestion.
                            </Card.Description>
                        </Card.Content>
                        <Card.Content position='right'>
                            <Link to="/editor">
                                <Button
                                color='yellow'>
                                Start Writing
                                </Button>
                            </Link>
                        </Card.Content>
                    </Card> */}
                </Segment>
            </Container>
        </div>
    );
}
export default Home;

