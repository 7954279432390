import React from 'react';
import './App.css';
import 'fomantic-ui-css/semantic.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import EditorComponent from './Editor';
import About from './About'
import Home from './Home'

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      
    }
  }
  
  render() {
    return(
      <Router>
        <Route path="/" exact component={Home}/>
        <Route path="/editor" exact component={EditorComponent}/>
        <Route path="/about" exact component={About}/>
      </Router>
    )
  }
}
export default App;
