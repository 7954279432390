import { Button, Icon, Segment} from "semantic-ui-react";
import React from 'react';

function Toolbar(props) {
    // const fontChoices = [
    //     { key: 'Times New Roman', text: 'Times New Roman', value: 'Times New Roman' },
    //     { key: 'Arial', text: 'Arial', value: 'Arial' },
    // ]
    return(    
        <div style={{
            paddingBottom: '20px'
        }}>
            {/* <Dropdown
                    fluid 
                    search 
                    selection
                    placeholder='some text'
                    options={fontChoices}>
            </Dropdown> */}
            <Segment>
                <Button.Group>
                    <Button icon onClick = {() => props.clickHandler("bold")}>
                        <Icon name='bold'/>
                    </Button>
                    <Button icon onClick = {() => props.clickHandler("italic")}>
                        <Icon name='italic'/>
                    </Button >
                    <Button icon onClick = {() => props.clickHandler("underline")}>
                        <Icon name='underline'/>
                    </Button>
                </Button.Group> {'  '}
            </Segment>
        </div>
    );
}
export default Toolbar;
