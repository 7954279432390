
import React, { useState } from 'react'
// import './App.css'
import 'fomantic-ui-css/semantic.css';
import { Segment, Search, Item, SegmentGroup, Header} from 'semantic-ui-react';
import axios from 'axios'

function SearchResultItem(props) {
    return (
        <Item>
            <Item.Header>{props.word}</Item.Header>
        </Item>
    )
}

function RhymeSearch(props) {

    const [loading, setLoading] = useState(false)
    const [perfectSearchResults, setPerfectSearchResults] = useState([])
    const [imperfectSearchResults, setImerfectSearchResults] = useState([])
    const [value, setValue] = useState("")
    
    console.log(value)

    const getPerfectRhymes = (userInput) => {
        const perfectRhymeQuery = `https://api.datamuse.com/words?rel_rhy=${userInput}`
        axios.get(perfectRhymeQuery).then(result => {
            setPerfectSearchResults(result.data)
            setLoading(false)
        })
        console.log(perfectSearchResults)
    }
    const getImperfectRhymes = (userInput) => {
        const imperfectRhymeQuery = `https://api.datamuse.com/words?rel_nry=${userInput}`

        axios.get(imperfectRhymeQuery).then(result => {
            setImerfectSearchResults(result.data)
            setLoading(false)
        })
        console.log(imperfectSearchResults)
    }

    const handleSearchChange = (e, data) => {
        console.log("data.value:")
        console.log(data.value)
        setValue(data.value)
    }

    const onKeyDown = e => {
        console.log("Key down entered!")
        //console.log(e.keyCode)
        if (e.keyCode === 13) { // enter key
            console.log("enter pressed")

            setLoading(true)

            getPerfectRhymes(value)
            getImperfectRhymes(value);
        }
    }

    return (
        <Segment color='yellow' style={{ overflow: 'auto', maxHeight: '60em' }}>
            <Header as='h1' color='yellow' content='Search for rhymes'/>
            <Search
                onSearchChange={handleSearchChange}
                loading={loading}
                value={value}
                showNoResults={false}
                icon={''}
                onKeyDown={onKeyDown}>
            </Search>

            {perfectSearchResults.length === 0 && imperfectSearchResults.length === 0 ? null : (
                <SegmentGroup horizontal>
                    <Segment style={{ overflow: 'auto', maxHeight: '100%' }}>
                    {console.log(perfectSearchResults)}
                        <Item.Header as='a'> Strong </Item.Header>
                        <Item.Group>
                            {        
                                perfectSearchResults.map(searchResult =>
                                    <SearchResultItem key={searchResult.word} word={searchResult.word} />
                                )
                            }
                        </Item.Group>
                    </Segment>
                    <Segment>
                        <Item.Header as='a'> Weak </Item.Header>
                        <Item.Group>
                            {
                                imperfectSearchResults.map(searchResult =>
                                    <SearchResultItem key={searchResult.word} word={searchResult.word} />
                                )
                            }
                        </Item.Group>
                    </Segment>
                </SegmentGroup>
            )}
        </Segment>
    )
}
export default RhymeSearch;