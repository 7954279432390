import {Container, Segment} from "semantic-ui-react";
import React from 'react';
import { createEditor, Editor} from 'slate'
import { Slate, Editable, withReact} from 'slate-react'
import Toolbar from './Components/Toolbar'
import RhymeSearch from './Components/RhymeSearch'

class EditorComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [{
                type: 'paragraph',
                children: [{ text: 'Start writing' }],
              },],
            editor: withReact(createEditor()),
        }
    }    

    render() {
        console.log('render function entered')
        const CustomEditor = {
            isMarkActive(editor, format) {
                console.log(`in isMarkActive: format = ${format}`)
                const marks = Editor.marks(editor)
                const format_boolean = marks ? marks[format] === true : false
                console.log(`does text already have this format: ${format_boolean}`)
                return marks ? marks[format] === true : false                
            },
            toggleMark(editor, format) { 
                const isActive = CustomEditor.isMarkActive(editor, format)
                if (isActive) {
                    Editor.removeMark(editor, format)
                    console.log(`mark getting toggled: ${format}`)
                } else {
                    Editor.addMark(editor, format, true)
                    console.log(`mark getting added: ${format}`)
                }
            },
        }


        const DefaultElement = props => {
            return <p {...props.attributes}>{props.children}</p>
        }

        const renderElement = props => {
            switch (props.element.type) {
                default:
                    return <DefaultElement {...props} />
            }
        }
        

        const Leaf = ({ attributes, children, leaf }) => {
            if (leaf.bold) {
              children = <strong>{children}</strong>
            }
          
            if (leaf.italic) {
              children = <em>{children}</em>
            }
          
            if (leaf.underline) {
              children = <u>{children}</u>
            }
          
            return <span {...attributes}>{children}</span>
          }
        const renderLeaf = props => {
            return <Leaf {...props} />
        }

        const clickHandler = (format) => {
            CustomEditor.toggleMark(this.state.editor, format)
        }
        
        // console.log(this.state)
        return(
            <Container textAlign='left' style={{borderRadius: '10px'}}>
                <Segment.Group horizontal>
                    <Segment fluid size='large' style={{
                        height: '100vh',
                        width: '75vh',
                        border: '2px solid black'
                        }}>
                        <Toolbar clickHandler={clickHandler} ></Toolbar>
                        <Slate editor={this.state.editor} 
                            value = {this.state.value} 
                            onChange = {
                                newValue => {
                                    console.log(newValue); 
                                    this.setState(state => ({...state, value: newValue}))
                                }
                            }>
                            <Editable
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            onKeyDown = {
                                event => {
                                    if(!event.ctrlKey && !event.metaKey) {
                                        return 
                                    }
                                    switch(event.key) {
                                        case "b": {
                                            event.preventDefault()
                                            clickHandler("bold")
                                            break
                                        }
                                        case "i": {
                                            event.preventDefault()
                                            clickHandler("italic")
                                            break
                                        }
                                        default:
                                            console.log(event.key)
                                    }
                                }
                            }/>
                        </Slate>
                    </Segment>
                    <RhymeSearch>
                    </RhymeSearch>
                </Segment.Group>
            </Container>
        );
    }
}
export default EditorComponent;